import { toast } from 'react-toastify'

export function createToastMessageFromServerResponse(data: any) {
  if (data.sMessage) {
    if (data.bError) {
      toast.error(data.sMessage, {
        position: 'bottom-center'
      })
    } else {
      toast.success(data.sMessage, {
        position: 'bottom-center'
      })
    }
  }
}

export function createToastMessageFromServerError(error: any) {
  if (error.response) {
    console.log(error.response.status)
    if (error.response.status === 403) {
      toast.error('Keine berechtigung! Bitte wieder anmelden!', {
        position: 'bottom-center'
      })
    } else {
      toast.error('Unbekannter Server Fehler!', {
        position: 'bottom-center'
      })
    }
  }
}

export function canUseWebPFunction() {
  const elem = document.createElement('canvas')

  if (elem.getContext && elem.getContext('2d')) {
    // was able or not to get WebP representation
    return elem.toDataURL('image/webp').indexOf('data:image/webp') == 0
  }

  // very old browser like IE 8, canvas not supported
  return false
}
