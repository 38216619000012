import * as React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import { ToastContainer } from 'react-toastify'
import Logo from '../components/Logo'
import Header from '../components/Header'
import Footer from '../components/Footer'
import Window from '../components/Window'
import 'react-toastify/dist/ReactToastify.css'

interface StaticQueryProps {
  site: {
    siteMetadata: {
      title: string
      description: string
      keywords: string
    }
  }
}

const IndexLayout: React.FC = ({ children }) => (
  <StaticQuery
    query={graphql`
      query IndexLayoutQuery {
        site {
          siteMetadata {
            title
            description
          }
        }
      }
    `}
    render={(data: StaticQueryProps) => (
      <>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: data.site.siteMetadata.description },
            { name: 'keywords', content: data.site.siteMetadata.keywords }
          ]}
        >
          <html lang="de" />
        </Helmet>
        <Logo />
        <Window additionalClass="Home">
          <Header />
          <Footer />
        </Window>
        <main className="main" id="main">
          <div className="content">{children}</div>
        </main>
        <ToastContainer />
      </>
    )}
  />
)

export default IndexLayout
