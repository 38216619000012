import * as React from 'react'
import { Link } from 'gatsby'

export default function Footer() {
  return (
    <footer className="footer">
      <div className="footer__text">
        <Link className="footer__text" activeClassName="active" to="/links">
          Links
        </Link>
        <span id="footer__dot">{'\u2022'}</span>
        <Link className="footer__text" activeClassName="active" to="/impressum">
          Impressum
        </Link>
      </div>
    </footer>
  )
}
