import React from 'react'
import { canUseWebPFunction } from '../utils/utils'

interface IProps {
  additionalClass: string
}

interface IState {
  canUseWebP: boolean
}

export default class Window extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props)
    this.state = {
      canUseWebP: false
    }
  }

  componentDidMount() {
    this.setState({
      canUseWebP: canUseWebPFunction()
    })
  }

  render() {
    const { canUseWebP } = this.state
    const { additionalClass, children } = this.props
    const className = canUseWebP ? `window--${additionalClass} webp` : `window--${additionalClass} no-webp`
    return <div className={className}>{children}</div>
  }
}
